const initialState = {
  chats: [],
  agents: [],
  entities: []
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHAT':
      return {
        ...state,
        chats: action.chats,
      };
    case 'FETCH_AGENT':
      return {
        ...state,
        agents: action.agents
      };
      case 'FETCH_ENTITY':
        return {
          ...state,
          entities: action.entities
        };
    default:
      return state;
  }
};

export default chatReducer;
