const initialState = {
    admins: []
  };
  
  const adminReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_ADMIN':
        return {
          ...state,
          admins: action.admins
        };
      default:
        return state;
    }
  };
  
  export default adminReducer;
  