// ** Initial State
const initialState = {
    tasks: [],
    selectedTask: {},
    params: {
      filter: '',
      q: '',
      sort: '',
      tag: ''
    },
    isLoading: false,
  }
  
  const TodoReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_TASKS_START':
        return { ...state, isLoading: true };
      case 'GET_TASKS':
        return { ...state, tasks: action.tasks, params: action.params, isLoading: false }
      case 'UPDATE_TASKS':
        return { ...state, isLoading: false  }
      case 'REORDER_TASKS':
        return { ...state, tasks: action.tasks, isLoading: false }
      case 'SELECT_TASK':
        return { ...state, selectedTask: action.task, isLoading: false}
      default:
        return state
    }
  }
  export default TodoReducer
  