// ** Logo
import logo from "@src/assets/images/icons/customIcons/WidgetIcon.svg";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <img
        loading="lazy"
        className="fallback-logo"
        src={logo}
        alt="logo"
        style={{ height: "80px", width: "80px" }}
      />
      <div className="">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
