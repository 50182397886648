// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import sidebar from './sidebar'
import chat from './chat'
import admin from './admin'
import todo from './todo'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  sidebar,
  chat,
  admin,
  todo
})

export default rootReducer
