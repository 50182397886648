const initialState = {
  entities: [],
  dashboardSearchPopup: false
}

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ENTITY':
      return {
        ...state,
        entities: action.entities,
      }

      case 'DASHBOARD_SEARCH_POPUP':
      return {
        ...state,
        dashboardSearchPopup: action.payload,
      }
    default:
      return state
  }
}

export default sidebarReducer
  