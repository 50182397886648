// **  Initial State
const initialState = {
  user: [],
  entity: [],
  token: "",
  selectedEntity: [],
  users: [],
  isVerified: false,
  user: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.user,
        entity: action.entity,
        selectedEntity: action.selectedEntity,
        token: action.token,
      };
    case "GET_ROLE_USERS":
      return {
        ...state,
        users: action.users,
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return {
        ...state,
        user: [],
        entity: [],
        selectedEntity: [],
        token: "",
        ...obj,
      };
      case "OTP":
      return {
        ...state,
        isVerified: action.isVerified,
      };
      case "GET_USER":
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export default authReducer;
